import dayjs from 'dayjs';
import { findIndex, isEmpty, isEqual, pick } from 'lodash-es';
import { useStorage } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import hotelConfig, { HOTEL_RESULT_ROWS } from '../../config/hotel-defaults';
import { useNuxtApp, defineStore, computed, useRoute, useI18n, useConfigStore, useTracker } from '#imports';
import {
  HotelSearchHistory,
  HotelTripAdvisorReview,
  Hotel,
  HotelResultHotelInfo,
  HotelDetailSearchParams,
  HotelSearchParams,
  HotelRoom,
  HotelSelectedRooms,
  HotelPopularList,
  HotelSharedLinkSearchParams,
  HotelSuggestion,
  HotelRecommendation,
  HotelRecommendationCity,
  HotelSummary,
  HotelPopularDestination,
  HotelSearchType,
  HotelResultFilterConfig,
  HotelResultFilters,
  HotelResultSort,
  HotelResultResponse,
  HotelSort,
  HotelViewMode,
  HotelByCity,
  HotelByCountry,
  HotelByAirport,
  HotelByCityStar,
} from '@/types/hotel';
import { Service } from '@/types/service';

export const useHotelService = () => {
  const { $apifront } = useNuxtApp();

  const getHotelSuggestions = (params: { query: string; lang: string; country?: string }) =>
    $apifront<Service<HotelSuggestion[]>>('/ho/autocomplete/search', { params });

  const getHotelRecommendations = (params: { country: string; currency: string; lang: string }) =>
    $apifront<Service<HotelRecommendation[]>>('/ho/promo/homepage-list', { params });

  const getHotelRecommendationsByCity = (params: { city: string; currency: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelRecommendation[] }>>('/contents/hotel/recommended-hotels', { params });

  const getHotelRecommendationCities = (params: { country: string; lang: string }) =>
    $apifront<Service<{ cities: HotelRecommendationCity[] }>>('/contents/hotel/popular-cities', { params });

  const getNearbyHotels = (params: {
    hotelId?: string;
    lat?: number;
    long?: number;
    lang: string;
    currency?: string;
  }) => $apifront<Service<{ count: number; hotels: HotelSummary[] }>>('/ho/hotel/nearest', { params });

  const getCheapestHotel = (params: { city: string; currency: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelRecommendation[] }>>('/contents/hotel/cheapest-hotels', { params });

  const getHotelPopularDestination = (params: { lang: string; country: string }) =>
    $apifront<Service<{ destinations: HotelPopularDestination[] }>>('/contents/hotel/popular-destinations', { params });

  const getTripadvisorRating = (params: { ids: string[] }) =>
    $apifront<Service<{ [id: string]: HotelTripAdvisorReview | null }>>('/ho/review/tripadvisor', {
      method: 'post',
      body: params,
    });

  const getHotelDetail = (params: HotelDetailSearchParams) =>
    $apifront<Service<{ hotel: Hotel; timestamp: number }>>('/ho/hotel/detail', { params });

  const getHotelNearby = (params: { hotelId?: string; lat?: number; lng?: number; lang: string }) => {
    let url = `/ho/hotel/nearest?lang=${params.lang}`;

    if (typeof params.hotelId === 'string') {
      url += `&hotelId=${params.hotelId}`;
    }

    if (typeof params.lat === 'number' && typeof params.lng) {
      url += `&lat=${params.lat}&long=${params.lng}`;
    }

    return $apifront<Service<{ count: number; hotels: HotelResultHotelInfo[] }>>(url, { params });
  };

  const hotelRoomLivecrawl = (
    params: Omit<HotelSearchParams, 'type'> & {
      child: number;
      currency: string;
      country: string;
      lang: string;
    },
    options?: any,
  ) =>
    $apifront<Service<{ searchId: string; requestId: string }>>('/ho/hotel/livecrawl-room', {
      method: 'post',
      body: params,
      ...options,
    });

  const hotelRoomLivecrawlMonitor = (searchId: string, options?: any) =>
    $apifront<Service<{ status: 'none' | 'done'; data: HotelRoom[] }>>('/ho/hotel/livecrawl-detail', {
      method: 'post',
      body: { searchId },
      ...options,
    });

  const getPopularHotel = (params: { lang: string }) =>
    $apifront<Service<{ hotels: HotelPopularList[] }>>('/ho/hotel/footer-list', { params });

  const hotelSubmit = (
    payload: Omit<HotelSearchParams, 'id' | 'type' | 'destName'> & {
      child: number;
      lang: string;
      currency: string;
      hotel: {
        hotelId: string;
        rooms: (HotelRoom & { roomCount: number })[];
      };
    },
  ) =>
    $apifront<Service<{ bookId: string }>>('/ho/book/hotel-submit', {
      method: 'post',
      body: payload,
    });

  const getHotelSharedLink = (params: HotelSharedLinkSearchParams) =>
    $apifront<Service<{ surl: string }>>('/hotel/share-link', {
      method: 'post',
      body: params,
    });

  const getSearchNameById = (params: { id: string; type: string; lang: string }, options?: any) =>
    $apifront<Service<{ name: string }>>('/ho/hotel/search-name', { params, ...options });

  const getHotels = (
    params: HotelSearchParams & {
      child: number;
      page: number;
      rows: number;
      sort: HotelResultSort;
      currency: string;
      country: string;
      lang: string;
      filter: HotelResultFilters;
      live: 0 | 2;
      imageWidth?: number;
      radius?: number;
    },
    options?: any,
  ) =>
    $apifront<Service<HotelResultResponse>>('/ho/hotel/search', {
      method: 'post',
      body: params,
      ...options,
    });

  const getHotelByCity = (params: { name: string; country: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelByCity }>>('/contents/hotel/city', { params });

  const getHotelByCountry = (params: { name: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelByCountry }>>('/contents/hotel/country', { params });

  const getHotelByAirport = (params: { code: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelByAirport }>>('/contents/hotel/airport', { params });

  const getHotelByCityStar = (params: { name: string; country: string; star: string; lang: string }) =>
    $apifront<Service<{ hotels: HotelByCityStar }>>('/contents/hotel/city-star', { params });

  return {
    getHotelSuggestions,
    getHotelRecommendations,
    getHotelRecommendationsByCity,
    getHotelRecommendationCities,
    getNearbyHotels,
    getCheapestHotel,
    getHotelPopularDestination,
    getTripadvisorRating,
    getHotelDetail,
    getHotelNearby,
    hotelRoomLivecrawl,
    hotelRoomLivecrawlMonitor,
    getPopularHotel,
    hotelSubmit,
    getHotelSharedLink,
    getSearchNameById,
    getHotels,
    getHotelByCity,
    getHotelByCountry,
    getHotelByAirport,
    getHotelByCityStar,
  };
};

export const useHotelSearchStore = defineStore('hotel-search', () => {
  /** temp solution to sync with blaze & forerunner store */
  const sharedVuex = useStorage<{
    'hotel-search': {
      searchHistory: (HotelSearchHistory & { timestamp: number })[];
    };
  }>(
    'shared-vuex',
    {
      'hotel-search': {
        searchHistory: [],
      },
    },
    undefined,
    {
      mergeDefaults: (storageValue) => {
        if (storageValue['hotel-search'] && storageValue['hotel-search'].searchHistory) {
          const filteredHotelSearchHistory = storageValue['hotel-search'].searchHistory.filter(
            (history) => history.id && !dayjs(history.checkInDate).isBefore(dayjs(), 'day'),
          );

          storageValue['hotel-search'].searchHistory = filteredHotelSearchHistory;
        }

        return storageValue;
      },
    },
  );

  const searchHistory = computed({
    get: () => sharedVuex.value?.['hotel-search']?.searchHistory ?? [],
    set: (params) => {
      if (!sharedVuex.value?.['hotel-search']) {
        sharedVuex.value['hotel-search'] = { searchHistory: [] };
      }

      sharedVuex.value['hotel-search'].searchHistory = params;
    },
  });

  function addSearchHistory(searchParams: HotelSearchHistory) {
    searchHistory.value = searchHistory.value.filter((hotel) => !dayjs(hotel.checkInDate).isBefore(dayjs(), 'date'));

    const propsToCheck = ['id', 'checkInDate', 'checkOutDate'];

    const existingHistoryIndex = findIndex(searchHistory.value, (history) =>
      isEqual(pick(history, propsToCheck), pick(searchParams, propsToCheck)),
    );

    if (existingHistoryIndex > -1) {
      searchHistory.value = searchHistory.value.filter((_, index) => index !== existingHistoryIndex);
    }

    searchHistory.value.unshift({
      ...searchParams,
      timestamp: dayjs().unix(),
    });

    if (searchHistory.value.length > 10) {
      searchHistory.value.pop();
    }
  }

  function deleteSearchHistory(id: string) {
    searchHistory.value = searchHistory.value.filter((history) => history.id !== id);
  }

  return {
    searchHistory,
    addSearchHistory,
    deleteSearchHistory,
  };
});

export const useHotelDetailStore = defineStore('hotel-detail', () => {
  const route = useRoute();
  const { locale } = useI18n();
  const { country, currency } = storeToRefs(useConfigStore());

  const searchParams = ref<HotelSearchParams | null>(null);
  const tripAdviorReviews = ref<
    | (HotelTripAdvisorReview & {
        count: number;
      })
    | null
  >(null);
  const rooms = ref<{ [blockId: string]: HotelRoom } | null>(null);
  const hotel = ref<Hotel | null>(null);
  const selectedRooms = ref<HotelSelectedRooms>({});
  const activeTabIndex = ref<{ index: number; scroll: boolean }>({ index: 0, scroll: false });
  const timePolicy = ref<{ checkInTime: string | null; checkOutTime: string | null }>({
    checkInTime: null,
    checkOutTime: null,
  });
  const hotelSharedLink = ref<{ [blockId: string]: { surl: string } }>({});

  const hotelRoomSearchParams = computed(() => {
    const { co, ci, ro, ad, ca } = route.query as any;
    let checkInDate;
    let checkOutDate;

    const slug = route.params.slug!.toString();
    const hotelId = slug.toString().substring(slug.lastIndexOf('.') + 1, slug.length);

    if (ci) {
      checkInDate = validateCheckInDate(ci as string);
      checkOutDate = validateCheckOutDate(co as string, checkInDate);
    }

    const rooms = (!!ro && +ro) || hotelConfig.defaultRoom;
    let adult = (!!ad && +ad) || hotelConfig.defaultAdult;

    if (rooms > adult) adult = rooms;

    return {
      id: hotelId,
      lang: locale.value.toLowerCase() === 'zh-tw' ? 'zh-TW' : locale.value,
      currency: currency.value!,
      country: country.value!,
      checkInDate,
      checkOutDate,
      rooms,
      adult,
      child: (!!ca && (ca as string).split(',').length) || hotelConfig.defaultChild.length,
      childAges: ca ? (ca as string).split(',').map((a) => +a) : hotelConfig.defaultChild,
    };
  });

  const isHotelClosed = computed(() => {
    return hotel.value?.isClosed;
  });

  const nights = computed(() => {
    if (isSet(route.query.co) && isSet(route.query.ci)) {
      return dayjs(route.query.co as string).diff(dayjs(route.query.ci as string), 'day');
    } else {
      return 0;
    }
  });

  const isEmptySelectedDate = computed(() => !route.query.ci);

  const totalSelectedRoomsCount = computed(() => {
    return Object.values(selectedRooms.value).reduce((res, room) => (res += room.roomCount), 0);
  });

  function addSelectedRoom(room: HotelRoom, count = 1) {
    selectedRooms.value[room.blockId] = {
      ...room,
      roomCount: count,
    };
  }

  function adjustSelectedRoomCount(blockId: string, count: number) {
    if (selectedRooms.value[blockId]) {
      if (count === 0) {
        delete selectedRooms.value[blockId];
      } else {
        selectedRooms.value[blockId]!.roomCount = count;
      }
    }
  }

  function selectTotalSelectedRoomsByRoomID(roomId: string, blockId: string) {
    return Object.values(selectedRooms.value)
      .filter((room) => room.roomId === roomId && room.blockId !== blockId)
      .reduce((total, room) => {
        return (total += room.roomCount);
      }, 0);
  }

  return {
    searchParams,
    tripAdviorReviews,
    rooms,
    hotel,
    selectedRooms,
    activeTabIndex,
    hotelRoomSearchParams,
    timePolicy,
    isEmptySelectedDate,
    nights,
    totalSelectedRoomsCount,
    hotelSharedLink,
    adjustSelectedRoomCount,
    addSelectedRoom,
    selectTotalSelectedRoomsByRoomID,
    isHotelClosed,
  };
});

export const useHotelResultStore = defineStore('hotel-result', () => {
  const route = useRoute();
  const { jitsuEvent } = useTracker();
  const { locale, t } = useI18n();
  const { country, currency } = storeToRefs(useConfigStore());

  const hotels = ref<HotelSummary[] | null>(null);
  const isPending = ref(true);
  const isFetchMoreHotels = ref(false);
  const hotelCount = ref<number>(0);
  const filterConfig = ref<HotelResultFilterConfig | null>(null);
  const sort = ref<HotelSort>((route.query.so as HotelSort) || HotelSort.RECOMMENDED);
  const filters = ref<HotelResultFilters>({
    types: [],
    facilities: [],
    stars: [],
    minPricePerNight: null,
    maxPricePerNight: null,
  });
  const filterCache = ref<{
    types: Array<{ id: number; name: string; count: number }>;
    facilities: Array<{ id: number; name: string; count: number }>;
  }>({
    types: [],
    facilities: [],
  });
  const page = ref<number>(1);
  const pages = ref<number>(1);
  const viewMode = ref<HotelViewMode>(HotelViewMode.LIST_VIEW);
  const error = ref<{ code: string; message: string } | null>(null);
  const liveCrawlSource = ref<null | AbortController>(null);
  const liveCrawlSignal = ref<null | AbortSignal>(null);
  const fetchAborted = ref(false);

  const searchParams = computed(() => {
    const childAges = (route.query.ca || route.query.childAges) as string;

    const childAgesMap = childAges ? childAges.split(',').map((age) => Number(age)) : [];

    return {
      id: route.query.id as string,
      type: (route.query.t || route.query.type) as HotelSearchType,
      checkInDate: (route.query.ci || route.query.checkInDate) as string,
      checkOutDate: (route.query.co || route.query.checkOutDate) as string,
      rooms: Number(route.query.ro || route.query.rooms),
      adult: Number(route.query.ad || route.query.adult),
      childAges: childAgesMap,
    } as HotelSearchParams;
  });

  const isFilterActive = computed(() => {
    const { mn, mx, st, fc, ty } = route.query;

    return !!mn || !!mx || !!st || !!fc || !!ty;
  });

  function beforeFetchHotels() {
    isPending.value = true;
    error.value = null;

    if (liveCrawlSource.value) {
      liveCrawlSource.value.abort();
      fetchAborted.value = true;
    }

    liveCrawlSource.value = new AbortController();
    liveCrawlSignal.value = liveCrawlSource.value.signal;
  }

  const fetchHotels = async (
    payload?: {
      page?: number;
      radius?: number;
    },
    mode: 'list' | 'map' = 'list',
  ) => {
    beforeFetchHotels();

    try {
      const { page: selectedPage = 1, radius } = payload ?? {};

      const params = {
        ...searchParams.value,
        child: searchParams.value.childAges.length,
        country: country.value!,
        currency: currency.value!,
        lang: locale.value,
        live: 2 as any,
        page: selectedPage,
        rows: HOTEL_RESULT_ROWS,
        sort: sort.value,
        filter: filters.value,
        ...(typeof radius === 'number' && { radius }),
      };

      page.value = selectedPage;

      const { result }: { result: HotelResultResponse } = await useHotelService().getHotels(params, {
        signal: liveCrawlSignal.value,
      });

      if (!isEmpty(result)) {
        if (page.value > 1 && mode === 'list') {
          hotels.value = hotels.value?.concat(result.hotels) ?? result.hotels;
        } else {
          hotels.value = result.hotels;
        }

        hotelCount.value = result.hotelsCount;
        pages.value = Math.ceil(result.hotelsCount / HOTEL_RESULT_ROWS);

        if (!isEmpty(result.filterList)) {
          filterConfig.value = result.filterList;
        }
      }

      if (isEmpty(result.hotels)) {
        if (isFilterActive.value) {
          const { st, ty, fc, mn, mx } = route.query;
          let filter = '';

          if (st) {
            filter += `st=${st}&`;
          }

          if (ty) {
            filter += `ty=${ty}&`;
          }

          if (fc) {
            filter += `fc=${fc}&`;
          }

          if (mn) {
            filter += `mn=${mn}&`;
          }

          if (mx) {
            filter += `mx=${mx}&`;
          }

          filter = filter.substring(0, filter.length - 1);

          jitsuEvent('system-response-status', {
            product: 'hotel',
            object_name: 'hotel-result',
            object_parameter: 'error',
            error_message: 'no-result',
            filter_status: 'on',
            filter,
          });
        } else {
          jitsuEvent('system-response-status', {
            product: 'hotel',
            object_name: 'hotel-result',
            object_parameter: 'error',
            error_message: 'no-result',
            filter_status: 'off',
            user_type: route.query.d as string,
          });
        }
      }
    } catch (e: any) {
      if (fetchAborted.value) return;

      jitsuEvent('system-response-status', {
        product: 'hotel',
        object_name: 'hotel-result',
        object_parameter: 'error',
        error_message: 'fetch-error',
      });

      error.value = {
        code: getApiErrorCode(e)!,
        message: getApiErrorMessage(e, t('global.somethingwrong'))!,
      };
    } finally {
      fetchAborted.value = false;
      isPending.value = false;
      liveCrawlSource.value = null;
      liveCrawlSignal.value = null;
      isFetchMoreHotels.value = false;
    }
  };

  return {
    hotels,
    isPending,
    hotelCount,
    filterConfig,
    sort,
    filters,
    page,
    pages,
    error,
    searchParams,
    viewMode,
    isFetchMoreHotels,
    filterCache,
    isFilterActive,
    fetchHotels,
  };
});
